import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import { Breakpoints } from '@powdr/constants';

export const StyledPopupForm = styled.div`
  display: flex;
  height: 100%;

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66%;
  padding: 0 5%;
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  ${Breakpoints.mobile(css`
    width: 100%;
    padding: 25px;
  `)}
`;

export const SubmitMessage = styled(WysiwygStyleWrapper)``;

export const ImageWrapper = styled.div`
  flex-grow: 1;

  ${Breakpoints.mobile(css`
    ${({ $desktop }) => $desktop && 'display: none;'}
  `)}

  ${Breakpoints.desktopOnly(css`
    ${({ $mobile }) => $mobile && 'display: none;'}
  `)}
`;

export const FormImage = styled(GatsbyImage)`
  height: 100%;
  object-fit: cover;
`;
