import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { FormFormbucket } from '@powdr/components';

import {
  ImageWrapper, FormImage, FormWrapper,
  StyledPopupForm,
  SubmitMessage,
} from './styles';

export const PopupForm = ({
  className,
  relationships,
  formName,
  submitMessage,
  passthroughFunc,
}) => {
  const [isShowSubmittedMessage, setShowSubmittedMessage] = useState(false);
  const image = relationships?.field_referenced_image?.relationships?.image || {};
  const mobileImage = relationships?.field_mobile_image?.relationships?.image || {};

  const onSubmit = () => {
    setShowSubmittedMessage(true);
  };

  return (
    <StyledPopupForm className={className}>
      <ImageWrapper $mobile>
        <FormImage
          image={mobileImage?.gatsbyImage}
          alt={mobileImage?.relationships?.media__image?.[0]?.thumbnail?.alt}
        />
      </ImageWrapper>
      <FormWrapper>
        {(!isShowSubmittedMessage) ? (
          <FormFormbucket
            formName={formName}
            additionalSubmitAction={onSubmit}
            passthroughFunc={passthroughFunc}
            isFullWidth
          />
        ) : (
          <SubmitMessage>
            {ReactHtmlParser(submitMessage?.value)}
          </SubmitMessage>
        )}
      </FormWrapper>
      <ImageWrapper $desktop>
        <FormImage
          image={image?.gatsbyImage}
          alt={image?.relationships?.media__image?.[0]?.thumbnail?.alt}
        />
      </ImageWrapper>
    </StyledPopupForm>
  );
};

PopupForm.propTypes = {
  className: PropTypes.string,
  relationships: PropTypes.shape({
    field_mobile_image: PropTypes.shape({
      relationships: PropTypes.shape({
        image: PropTypes.shape({}),
      }),
    }),
    field_referenced_image: PropTypes.shape({
      relationships: PropTypes.shape({
        image: PropTypes.shape({}),
      }),
    }),
  }),
  formName: PropTypes.string.isRequired,
  submitMessage: PropTypes.shape({
    value: PropTypes.string,
  }),
  passthroughFunc: PropTypes.func,
};

PopupForm.defaultProps = {
  className: '',
  relationships: null,
  submitMessage: null,
  passthroughFunc: false,
};
