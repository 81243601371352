/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import {
  Components, Breakpoints, ThemeProps as Prop,
  FontFamily,
} from '@powdr/constants';
import {
  themeUtil, rgbaTransparencyToHex, clamp,
  getFontStyles,
} from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK;

export const ContentBlockWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 0 1 ${({
    columnOption, paddingAmount,
  }) => ((!columnOption)
    ? '100%'
    : `calc(${100 / (columnOption)}% - ${(paddingAmount / columnOption) * (columnOption - 1)}%)`)};
  max-width: ${({
    columnOption, paddingAmount,
  }) => ((!columnOption)
    ? '100%'
    : `calc(${100 / (columnOption)}% - ${(paddingAmount / columnOption) * (columnOption - 1)}%)`)};


  ${Breakpoints.mobile(css`
    flex: ${({ columnOptionMobile, paddingAmountMobile }) => ((!columnOptionMobile)
    ? '100%'
    : `calc(${100 / columnOptionMobile}% - ${(paddingAmountMobile / columnOptionMobile) * (columnOptionMobile - 1)}%)`)};
    max-width: ${({ columnOptionMobile, paddingAmountMobile }) => ((!columnOptionMobile)
    ? '100%'
    : `calc(${100 / columnOptionMobile}% - ${(paddingAmountMobile / columnOptionMobile) * (columnOptionMobile - 1)}%)`)};
  `)}

  &[class*="cbgroup-block"] {
    max-width: unset;
    flex: unset;
  }

  h3 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, undefined, undefined, CPNT, 'header')};
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};

    ${Breakpoints.small(css`
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 0.9, undefined, CPNT, 'header')};
    `)}

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 0.9, undefined, CPNT, 'headerMobile')};
    `)}
  }

  p {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, CPNT, 'body')};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, CPNT, 'body')};
    `)}

    ${Breakpoints.larger(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, CPNT, 'body')};
    `)}
  }

  .cb-title {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  }

  .cb-copy {
    line-height: normal;
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  small {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, CPNT, 'small')};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    line-height: 1.1;
  }

  .button {
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined, CPNT, 'button')};
    line-height: normal;
    padding: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    transition: color 250ms ease-out, background-color 250ms ease-in-out;

    &:hover, &:focus {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      }
    }

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }

    &.with-background {
      padding: 12px 16px;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

      &:hover, &:focus {
        background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      }

      &:focus {
        outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
        outline-offset: 2px;
      }
    }

    &:not(.with-background) {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

      &:hover, &:focus {
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }

      &:focus {
        outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        outline-offset: 2px;
      }
    }

    &.capitalize {
      text-transform: capitalize;
    }
  }

  .cb-icon {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    }
  }

  .range-dash {
    display: inline-block;
    vertical-align: middle;
    margin-top: -7px;
  }

  [class*="content-block-"] {
    ${({ isFullHeight }) => ((isFullHeight) ? 'height: 100%;' : '')}
  }

  .carousel-control-group {
    display: flex;
    justify-content: space-between;
    pointer-events: none;

    .cb-control-next, .cb-control-previous {
      pointer-events: all;

      .control-arrow-inner {
        position: relative;
        z-index: 16;
      }

      .control-arrow-icon {
        margin-left: 5px;

        svg {
          fill: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
          color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
        }
      }

      &:after {
        content: " ";
        position: absolute;
        top: calc(50% - 35px);
        border: solid transparent;
        border-width: 35px;
        width: 0;
        height: 0;
        z-index: 15;
      }
    }

    .cb-control-previous {

      &:after {
        border-left-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
        left: 0;
      }

      &:hover {
        svg {
          fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
          color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
        }

        &:after {
          border-left-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
        }
      }
    }

    .cb-control-next {

      &:after {
        border-right-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
        right: 0;
      }

      &:hover {
        svg {
          fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
          color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
        }

        &:after {
          border-right-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
        }
      }
    }

    ${Breakpoints.mobile(css`

    `)}
  }
`;

export const ContentBlockOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 18;
  background-color: ${() => `#000000${rgbaTransparencyToHex(0.75)}`};

  ${({ blockType }) => {
    if (blockType === 'flag') {
      return `
        padding: 15px;

        .overlay-title {
          margin-bottom: 0px;
        }

        .overlay-message {
          margin: 0 10px;
          ${() => clamp(3)}
        }
      `;
    }

    if (blockType === 'image-flag') {
      return `
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding: 15px;

        .overlay-title {
          margin-bottom: 10px;
          ${() => clamp(2)}
        }

        .overlay-message {
          margin-bottom: 10px;
          ${() => clamp(2)}
        }
      `;
    }

    return `
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 50px;

      .overlay-title {
        margin-bottom: 15px;
        ${() => clamp(2)}
      }

      .overlay-message {
        margin-bottom: 15px;
        ${() => clamp(4)}
      }
    `;
  }}

  .overlay-title {
    color: white;
  }

  .overlay-message {
    color: white;
  }
`;

export const ExpandCollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transition: height 0.6s ease-out, padding 0.1s ease-out 0.4s;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  width: 100%;
`;

export const ExpandCollapseContent = styled(WysiwygStyleWrapper)`
  width: 100%;
  animation-delay: 0.6s;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const CloseButton = styled.button`
  animation-delay: 0.6s;
  margin-left: 15px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  &:hover {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const VideoModalPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const FrameWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 75%;
  padding-bottom: 42.1875%;

  .video-block-modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
