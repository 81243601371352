import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontFamily,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.RATES_AND_DATES_TABS;

export const StyledRatesAndDatesGroup = styled.div``;

export const RatesDatesPanelInner = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  padding: 50px 7.5% 15px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};

  ${Breakpoints.mobile(css`
    gap: 30px;
    flex-direction: column;
  `)}

  ${Breakpoints.small(css`
    padding: 25px 3.5% 15px;
  `)}
`;

export const CampList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const CampListItem = styled.li``;

export const CampBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;

  ${Breakpoints.mobile(css`
    gap: 5px;
  `)}
`;

export const CampLeftWrapper = styled.div``;

export const CampTitle = styled.h2`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '26px', undefined, undefined)};
`;

export const CampSubheader = styled.p`
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined)};
`;

export const CampButton = styled(Link)`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined)};
  height: fit-content;
  padding: 8px 10px;
  line-height: normal;
  white-space: nowrap;
  color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, $colorProfile)};
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
  transition: color 250ms ease-out, background-color 250ms ease-in-out;

  &:hover, &:focus {
    color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)};
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};

    svg {
      fill: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)};
      color: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)};
    }
  }

  &:focus {
    outline: 2px solid ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
    outline-offset: 2px;
  }
`;

export const PhotoGridWrapper = styled.div``;

export const PhotoGridTitle = styled.h2`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '26px', undefined, undefined)};
  margin-bottom: 15px;
`;

export const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(${({ $count }) => (($count < 3) ? 1 : 2)}, 1fr);
  gap: 25px 50px;
`;

export const PhotoGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Photo = styled.img`
  aspect-ratio: 1/1;
  object-fit: cover;

  ${Breakpoints.desktopOnly(css`
    max-height: 150px;
  `)}
`;

export const Caption = styled.div``;
