// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { UiEmbed } from '@powdr/components';
import { getImageAltText } from '@powdr/utils';

import {
  StyledSlideItem, SlideImageStatic, SlideImageDynamic,
} from './styles';

export const SlideItem = ({
  embeddedVideoLink,
  relationships,
  staticImage,
  disabled,
}) => {
  const image = relationships?.primaryImage?.relationships?.image?.gatsbyImage || null;

  return (
    <StyledSlideItem>
      {(embeddedVideoLink) && <UiEmbed videoLink={embeddedVideoLink} disabled={disabled} />}
      {(image) && (
        <SlideImageDynamic
          image={image}
          alt={getImageAltText(image?.gatsbyImage)}
        />
      )}
      {(!image && staticImage) && <SlideImageStatic src={staticImage} alt="" />}
      {/* {(!image?.attribution) && <ImageAttribution>{i?.attribution}</ImageAttribution>} */}
    </StyledSlideItem>
  );
};

SlideItem.propTypes = {
  embeddedVideoLink: PropTypes.string,
  staticImage: PropTypes.string,
  disabled: PropTypes.bool,
  relationships: PropTypes.shape().isRequired,
};

SlideItem.defaultProps = {
  embeddedVideoLink: null,
  staticImage: null,
  disabled: false,
};
