/* eslint-disable max-len */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LoadScript } from '@powdr/components';
import { AlternatingBlockLayoutOptions, LinkTargets } from '@powdr/constants';
import { getImageAltText, getImageAttribution, textFilter } from '@powdr/utils';

export const globalContentParser = (html) => {
  if (!html) return html;

  const transform = (node) => {
    if (node.type === 'script') return (<LoadScript node={node} />);
  };
  const elements = ReactHtmlParser(html, { transform });

  return elements;
};

export const cleanSliderData = (sliderData) => {
  if (sliderData?.relationships) return sliderData;
  const slides = (sliderData?.items) ? sliderData.items : sliderData.field_slide;
  return {
    isThumbnailsEnabled: sliderData?.field_thumbnails,
    relationships: {
      slides: !Array.isArray(slides) ? [{
        staticImage: slides?.field_image?.default,
        embeddedVideoLink: slides?.field_embedded_video_link,
      }] : slides.map((item) => ({
        staticImage: item?.field_image?.default,
        embeddedVideoLink: item?.field_embedded_video_link,
      })),
    },
  };
};

export const cleanTableData = (tableData) => Object.values(tableData).filter((row) => (typeof row !== 'string'));

// handles external view links by adding corresponding property name to href if it is relative path
const fixExternalHref = (href, property) => ((href?.includes('http')) ? href : `https://www.${property}.com${href}`);

export const aliasContentBlockDataFromViews = (cb, isExternalView) => ({
  ...cb,
  id: (cb?.nid) ? parseInt(cb.nid, 10) : `dynamic-block-${Math.random()}`,
  title: textFilter(cb.title || cb.field_title),
  copy: textFilter(cb.field_copy || cb.field_short_description)?.replace(/<[^>]+>/g, ''),
  body: cb.body,
  formattedContent: {
    value: cb.body,
  },
  shortDescription: textFilter(cb.field_short_description),
  buttonText: cb.field_btn,
  buttonLink: (isExternalView) ? fixExternalHref(cb.field_btn_href, cb.property) : cb.field_btn_href,
  buttonTarget: (isExternalView) ? LinkTargets.BLANK : cb.field_link_target || cb.field_target,
  buttonIconName: cb.field_btn_icon,
  iconName: textFilter(cb.field_icon_name),
  iconLink: cb.field_icon_link,
  accentIconName: cb.field_accent_icon_name,
  isHideBlock: cb.field_hide_block,
  isOverlayEnabled: cb.field_overlay_enabled,
  overlayMessage: textFilter(cb.field_overlay_message),
  isPlainText: cb.field_plain_text,
  videoId: cb.field_video_id,
  date: cb.field_date,
  endDate: cb.field_end_date,
  viewImage: cb.field_image,
  price: cb.field_price,
  priceHeader: textFilter(cb.field_price_header),
  altButtonText: cb.field_alt_button_text,
  altButtonLink: cb.field_alt_button_link,
  altButtonTarget: (isExternalView) ? LinkTargets.BLANK : cb.field_alt_button_target,
  subheader: {
    value: cb.field_formatted_subheader,
  },
  iconHeight: cb?.field_height || cb.field_icon_height,
  tags: textFilter(cb.field_tags),
  hours: cb.field_hours,
  location: cb.field_location,
  locationDetails: cb.field_location_details,
  phoneNumber: cb.field_phone_number,
  phoneNumberDetails: cb.field_phone_number_details,
  mealType: cb.field_filter_meal_type,
  priceRange: (cb.field_price_range === '') ? '$' : cb.field_price_range,
  reversibleTable: {
    isReversedData: cb.field_reversible_table?.field_reverse_data,
    tables: cb.field_reversible_table?.field_table,
  },
  slider: (cb?.field_slider) ? cleanSliderData(cb.field_slider) : null,
  status: cb?.field_dynamic_status || null,
  detailComponent: {
    label: cb?.field_detail_component_label || null,
    data: cb?.field_detail_component || null,
  },
});

// strip the field_ from object keys
export const stripDrupalFieldFromObjKeys = (o) => Object.fromEntries(
  Object.entries(o).map(([k, v]) => [`${k.replace('field_', '')}`, v]),
);

export const getAlternatingLayoutState = (idx, alternateBlockLayout) => {
  // if setting is not turned on/disabled, always return default
  if (!alternateBlockLayout) return AlternatingBlockLayoutOptions.DEFAULT;

  // if alternation is set to non-default layout ONLY
  if (alternateBlockLayout === AlternatingBlockLayoutOptions.NON_DEFAULT_ONLY) return AlternatingBlockLayoutOptions.NON_DEFAULT;

  // if alternation is set to default layout first
  if (alternateBlockLayout === AlternatingBlockLayoutOptions.DEFAULT_FIRST) {
    return (idx % 2 === 0) ? AlternatingBlockLayoutOptions.DEFAULT : AlternatingBlockLayoutOptions.NON_DEFAULT;
  }

  // if alternation is set to non-default layout first
  return (idx % 2 === 0) ? AlternatingBlockLayoutOptions.NON_DEFAULT : AlternatingBlockLayoutOptions.DEFAULT;
};

export const sortNavigationByWeight = (childPages) => childPages.reduce((allPages, childPage) => {
  if (childPage.relationships?.pages) {
    allPages.push({
      ...childPage,
      childPages: sortNavigationByWeight(childPage.relationships.pages
        .sort((a, b) => a.weight - b.weight)),
      // relationships: null,
      season: childPage.navRelationships?.season?.name?.toLowerCase() || null,
    });
  } else {
    allPages.push({
      ...childPage,
      childPages: [],
      // relationships: null,
      season: childPage.navRelationships?.season?.name?.toLowerCase() || null,
    });
  }
  return allPages;
}, []);

export const handleApiObjArr = (d) => ((Array.isArray(d)) ? d : [d]);

export const getImageData = (i) => ((!i?.relationships?.image)
  ? {}
  : {
    altText: getImageAltText(i.relationships.image),
    attribution: getImageAttribution(i.relationships.image),
    src: i.relationships.image.gatsbyImage,
  }
);
