/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useContext, useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import ReactHtmlParser from 'react-html-parser';
import { useQueryParams, StringParam } from 'use-query-params';

import { Icon, Link } from '@powdr/components';
import {
  ContentBlocks, QueryParamNames, BlockTypes, DefaultAriaLabels, AlternatingBlockLayoutOptions,
} from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { usePlaceStatus } from '@powdr/hooks';
import {
  colorProfileByIndex,
  camalizeToTitle,
  getImageData,
} from '@powdr/utils';

import {
  ContentBlockWrapper, ContentBlockOverlay, ExpandCollapseContainer,
  ExpandCollapseContent, CloseButton,
} from './styles';

export const ContentBlock = ({
  index, contentBlock, blockType, columnLayout, columnOption, columnOptionMobile,
  revealHorizontalLayout, itemPaddingAmount, itemPaddingAmountMobile, isExpandCollapseBlocks, isUsingBrandingColors,
  colorProfile, className, isFullHeight, alternateBlockLayoutState, textDecoration, isHideTags, verticalAlignment,
}) => {
  if (!contentBlock) return null; // here for handling CMS entry issues
  const [, setQueryParams] = useQueryParams({ [QueryParamNames.VIDEO]: StringParam });
  const [expandToggle, setExpandToggle] = useState(false);
  const {
    title, isOverlayEnabled, overlayMessage, relationships, videoId,
    buttonText, buttonLink, buttonTarget, altButtonText, altButtonLink,
    expandContent, staticImageUrl, isIngestAsHtml, viewImage, tags,
  } = contentBlock;
  const { isMobile } = useContext(AppContext);
  const imageData = getImageData(relationships?.field_referenced_image || null);
  const buttonEnabled = !!((buttonLink || (buttonText && videoId) || isExpandCollapseBlocks));
  const altButtonEnabled = (altButtonText && altButtonLink);
  const isStaticImageLink = !!(!imageData?.gatsbyImage && (staticImageUrl?.uri || viewImage));
  const isVideoBlock = !!(videoId);
  const image = isStaticImageLink
    ? {
      altText: '',
      attribution: '',
      src: staticImageUrl?.uri || viewImage.default,
    }
    : imageData;
  const expandContentHtml = expandContent?.processed;
  const ariaLabel = buttonText || DefaultAriaLabels.CLICK_HERE;
  const prettifyTags = (Array.isArray(tags)) ? tags.map((s) => camalizeToTitle(s)).join(', ') : tags;
  const status = usePlaceStatus(contentBlock.status);

  const buttonClick = () => {
    setExpandToggle(!expandToggle);

    if (isVideoBlock) {
      setQueryParams((prevState) => ({ ...prevState, [QueryParamNames.VIDEO]: videoId }));
    }
  };

  // If individual content block has a color profile overide the parent profile
  const cbColorProfile = contentBlock.colorProfile ? colorProfileByIndex(contentBlock.colorProfile) : colorProfile;

  return (
    <ContentBlockWrapper
      className={className}
      isMobile={isMobile}
      colorProfile={cbColorProfile}
      columnOption={columnOption}
      columnOptionMobile={columnOptionMobile}
      paddingAmount={itemPaddingAmount}
      paddingAmountMobile={itemPaddingAmountMobile}
      isFullHeight={isFullHeight}
    >
      {(isOverlayEnabled) && (
        <ContentBlockOverlay blockType={blockType}>
          <h3 className="overlay-title">{((isIngestAsHtml) ? ReactHtmlParser(title) : title) || ''}</h3>
          <p className="overlay-message">{overlayMessage}</p>
          {(buttonEnabled) && (
            <Link
              type="button"
              aria-label={ariaLabel}
              href={buttonLink}
              target={buttonTarget}
              className="button with-background"
            >
              {buttonText}
            </Link>
          )}
        </ContentBlockOverlay>
      )}
      {React.createElement(
        ContentBlocks[blockType],
        {
          ...contentBlock,
          key: `id-${Math.random}`,
          index,
          colorProfile: cbColorProfile,
          image,
          imageCarousel: relationships?.imageCarousel?.length > 0 ? relationships?.imageCarousel : null,
          buttonEnabled,
          altButtonEnabled,
          ariaLabel,
          blockType,
          columnLayout,
          columnOption,
          columnOptionMobile,
          revealHorizontalLayout,
          isExpandCollapseBlocks,
          isUsingBrandingColors,
          isStaticImageLink,
          alternateBlockLayoutState,
          textDecoration,
          title: (isIngestAsHtml) ? ReactHtmlParser(contentBlock?.title) : contentBlock?.title,
          copy: (isIngestAsHtml) ? ReactHtmlParser(contentBlock?.copy) : contentBlock?.copy,
          formattedContent: contentBlock?.formattedContent?.value,
          subheader: contentBlock?.subheader?.value,
          buttonClick,
          buttonText: (isExpandCollapseBlocks && expandToggle) ? 'Close' : buttonText,
          buttonLink: (!isExpandCollapseBlocks && !isVideoBlock) ? buttonLink : null, // don't pass button data if expand collapse enabled, do not pass link if video block
          buttonTarget: (!isExpandCollapseBlocks && !isVideoBlock) ? buttonTarget : null, // don't pass button data if expand collapse enabled, do not pass target if video block
          tags: (isHideTags) ? null : prettifyTags,
          status,
          isFullHeight,
          verticalAlignment: (verticalAlignment) || contentBlock?.verticalAlignment,
          inert: (!buttonLink && !buttonEnabled && !isExpandCollapseBlocks && !isVideoBlock) ? '' : null,
        },
      )}
      {(isExpandCollapseBlocks) && (
        <AnimateHeight duration={500} height={(expandToggle) ? 'auto' : 0}>
          <ExpandCollapseContainer>
            <ExpandCollapseContent className={classNames({ 'animated fadeInUp': expandToggle })}>
              {ReactHtmlParser(expandContentHtml)}
            </ExpandCollapseContent>
            <CloseButton
              type="button"
              colorProfile={cbColorProfile}
              onClick={() => setExpandToggle(!expandToggle)}
              className={classNames({ 'animated fadeInUp': expandToggle })}
            >
              <Icon name="ui-close-circle" width="40" height="40" />
            </CloseButton>
          </ExpandCollapseContainer>
        </AnimateHeight>
      )}
    </ContentBlockWrapper>
  );
};

ContentBlock.propTypes = {
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  contentBlock: PropTypes.shape().isRequired,
  blockType: PropTypes.oneOf(Object.values(BlockTypes)).isRequired,
  columnLayout: PropTypes.string,
  columnOption: PropTypes.number,
  columnOptionMobile: PropTypes.number,
  revealHorizontalLayout: PropTypes.string,
  itemPaddingAmount: PropTypes.number,
  itemPaddingAmountMobile: PropTypes.number,
  isExpandCollapseBlocks: PropTypes.bool,
  isUsingBrandingColors: PropTypes.bool,
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alternateBlockLayoutState: PropTypes.oneOf(Object.values(AlternatingBlockLayoutOptions)),
  viewImage: PropTypes.shape(),
  isFullHeight: PropTypes.bool,
  textDecoration: PropTypes.string,
  isHideTags: PropTypes.bool,
  verticalAlignment: PropTypes.string,
};

ContentBlock.defaultProps = {
  className: '',
  columnLayout: null,
  columnOption: null,
  columnOptionMobile: null,
  revealHorizontalLayout: null,
  itemPaddingAmount: null,
  itemPaddingAmountMobile: null,
  isExpandCollapseBlocks: null,
  isUsingBrandingColors: null,
  alternateBlockLayoutState: AlternatingBlockLayoutOptions.DEFAULT,
  colorProfile: null,
  viewImage: null,
  isFullHeight: false,
  textDecoration: null,
  isHideTags: false,
  verticalAlignment: null,
};
