/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import theme from '@theme';
import React, { useCallback, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Season } from '@powdr/constants';
import { useScrollPosition } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';

export const AppContext = React.createContext(null);

// eslint-disable-next-line react/prop-types
export const AppContextProvider = ({ children }) => {
  const { defaultSeason } = useStaticData();
  const [isMobile, setIsMobile] = useState(false);
  const [isWoodwardColorTakeover, setIsWoodwardColorTakeover] = useState(false);
  const [uiSeason, setUiSeason] = useState(defaultSeason);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDisablePageScroll, setDisablePageScroll] = useState(false);

  const [defaultTheme, setDefaultTheme] = useState({
    ...theme, ...theme.defaultTheme, ...theme.StaticTheme,
  });

  const handleTheme = useCallback((season, isWWTakeover) => {
    if (season === Season.SUMMER) {
      setDefaultTheme(
        {
          ...theme,
          ...(isWWTakeover) ? theme.wwAltTheme : theme.altTheme,
          ...theme.StaticTheme,
        },
      );
    } else {
      setDefaultTheme(
        {
          ...theme,
          ...(isWWTakeover) ? theme.wwDefaultTheme : theme.defaultTheme,
          ...theme.StaticTheme,
        },
      );
    }
  }, []);

  // add or remove no scroll class from body
  const toggleBodyScroll = useCallback((bool) => {
    setDisablePageScroll(bool);
    if (bool) {
      document.body.classList.add('u-noscroll');
    } else {
      document.body.classList.remove('u-noscroll');
    }
  }, []);

  // HEADER CONTEXT
  // scroll position
  const scrollPosition = useScrollPosition();

  // season settings
  const [hasScrolledSeason, setHasScrolledSeason] = useState(false);
  const [seasonTabActive, setSeasonTabActive] = useState(false);

  const [isHeaderScrolled, setHeaderScrolled] = useState(false);
  const [fullHeaderHeight, setFullHeaderHeight] = useState(0);

  // levels
  const [isCancelScroll, setIsCancelScroll] = useState(false);

  // Alert bar
  const [closedAlert, setClosedAlert] = useState(false);
  const [alertHeight, setAlertHeight] = useState();

  // Inner Navigations
  const [secondLevelNavHeight, setSecondLevelNavHeight] = useState();

  // Navigation Drawer
  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const [isMobileThirdLevelOpen, setIsMobileThirdLevelOpen] = useState(false);
  const [shiftPromos, setShiftPromos] = useState(false);

  // DOR
  const [isDorDrawerOpen, setDorDrawerOpen] = useState(false);

  // QR Query Card takeover handling
  const [isQrQueryCardPageActive, setQrQueryCardPageActive] = useState(false);
  const [singleItemView, setSingleItemView] = useState('');

  const setDrawerOpen = useCallback((bool, drawerStateFunc) => {
    toggleBodyScroll(bool);
    drawerStateFunc(bool);
    setIsDrawerOpen(bool);
    // setIsMobileThirdLevelOpen(false);
  }, [toggleBodyScroll]);

  const toggleThirdLevel = useCallback((bool) => {
    setIsMobileThirdLevelOpen(bool);
  }, []);

  const context = useMemo(() => ({
    // APP
    svgs: theme.svgs,
    themeConfig: theme.config,
    defaultTheme,
    isMobile,
    setIsMobile,
    handleTheme,

    uiSeason,
    setUiSeason,

    hasScrolledSeason,
    setHasScrolledSeason,
    seasonTabActive,
    setSeasonTabActive,

    isDisablePageScroll,
    setDisablePageScroll,

    toggleBodyScroll,
    scrollPosition,
    isHeaderScrolled,
    setHeaderScrolled,
    closedAlert,
    setClosedAlert,
    alertHeight,
    setAlertHeight,

    secondLevelNavHeight,
    setSecondLevelNavHeight,

    isWoodwardColorTakeover,
    setIsWoodwardColorTakeover,
    isCancelScroll,
    setIsCancelScroll,

    isDrawerOpen,
    setIsDrawerOpen,
    setDrawerOpen,
    isDorDrawerOpen,
    setDorDrawerOpen,
    isNavigationDrawerOpen,
    setNavigationDrawerOpen,
    shiftPromos,
    setShiftPromos,
    isMobileThirdLevelOpen,
    toggleThirdLevel,
    fullHeaderHeight,
    setFullHeaderHeight,
    isQrQueryCardPageActive,
    setQrQueryCardPageActive,
    singleItemView,
    setSingleItemView,
  }), [
    // APP
    defaultTheme,
    isMobile,
    setIsMobile,
    handleTheme,

    uiSeason,
    setUiSeason,

    hasScrolledSeason,
    setHasScrolledSeason,
    seasonTabActive,
    setSeasonTabActive,

    isDisablePageScroll,
    setDisablePageScroll,

    toggleBodyScroll,
    scrollPosition,
    isHeaderScrolled,
    setHeaderScrolled,
    closedAlert,
    setClosedAlert,
    alertHeight,
    setAlertHeight,

    secondLevelNavHeight,
    setSecondLevelNavHeight,

    isWoodwardColorTakeover,
    setIsWoodwardColorTakeover,
    isCancelScroll,
    setIsCancelScroll,

    isDrawerOpen,
    setIsDrawerOpen,
    setDrawerOpen,
    isDorDrawerOpen,
    setDorDrawerOpen,
    isNavigationDrawerOpen,
    setNavigationDrawerOpen,
    shiftPromos,
    setShiftPromos,
    isMobileThirdLevelOpen,
    toggleThirdLevel,
    fullHeaderHeight,
    setFullHeaderHeight,
    isQrQueryCardPageActive,
    setQrQueryCardPageActive,
    singleItemView,
    setSingleItemView,
  ]);

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={defaultTheme}>
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};
